import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-titulo-funcionalidade',
  templateUrl: './titulo-funcionalidade.component.html',
  styleUrls: ['./titulo-funcionalidade.component.scss'],
})
export class TituloFuncionalidadeComponent {
  @Input() h1 = '';
  @Input() h2 = '';

  constructor() {}
}
