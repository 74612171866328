<div *ngIf="
    showUserDropdown ||
    showEixosDropdown ||
    showHelpDropDown ||
    showNotificationDropdown ||
    showCompaniesDropdown
  "
     (click)="
    showUserDropdown = false;
    showEixosDropdown = false;
    showHelpDropDown = false;
    showNotificationDropdown = false;
    showCompaniesDropdown = false
  "
     class="dropdown-overlay"></div>
<div class="header-container fixed-top">
  <header class="header navbar navbar-expand-sm">
    <ul class="navbar-item theme-brand flex-row text-center">
      <li class="nav-item theme-logo">
        <a href="index.html">
          <svg class="logo">
            <use class="logo_icon"
                 xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="#logo-planejar"></use>
          </svg>
        </a>
      </li>
      <li class="nav-item">
        <div class="logoPref">
          <div>
            <img [src]="logo" />
          </div>
          <div class="thema-text-mobile">
            <h4 class="lb">Estrutura:</h4>
            <p>{{ organizacaoCliente }}</p>
          </div>
        </div>
      </li>
    </ul>

    <ul class="navbar-item flex-row align-items-center ml-md-auto"
        style="height: 45px;">

      <app-eixos [showEixosDropdown]="showEixosDropdown"
                 (clickEixosDropDown)="
          showUserDropdown = false;
          showEixosDropdown = true;
          showHelpDropDown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "></app-eixos>

      <app-notificacoes [showNotificationDropdown]="showNotificationDropdown"
                        (clickNotificationDropDown)="
          showUserDropdown = false;
          showEixosDropdown = false;
          showHelpDropDown = false;
          showNotificationDropdown = true;
          showCompaniesDropdown = false
        "></app-notificacoes>

      <!-- <app-empresas [showCompaniesDropdown]="showCompaniesDropdown"
                    [user]="false"
                    (clickCompaniesDropDown)="
          showUserDropdown = false;
          showEixosDropdown = false;
          showHelpDropDown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = true
        "></app-empresas> -->

      <app-usuario [showUserDropdown]="showUserDropdown"
                   [user]="true"
                   [webUser]="false"
                   (clickUserDropDown)="
          showUserDropdown = true;
          showEixosDropdown = false;
          showHelpDropDown = false;
          showNotificationDropdown = false;
          showCompaniesDropdown = false
        "></app-usuario>

      <li class="user-atach">
        <div class="nomeCargo">
          <p>{{ nomeCliente }}</p>
          <span>{{ perfilCliente }}</span>
        </div>
      </li>

      <app-usuario class="user-mobile-responsive"
                   [showUserDropdown]="showUserDropdown"
                   [user]="false"
                   [webUser]="true"
                   (clickUserDropDown)="
        showUserDropdown = true;
        showEixosDropdown = false;
        showHelpDropDown = false;
        showNotificationDropdown = false;
        showCompaniesDropdown = false
      ">
        <div class="nomeCargo">
          <p>{{ nomeCliente }}</p>
          <span>{{ organizacaoCliente }}</span>
        </div>
      </app-usuario>

    </ul>
  </header>
</div>