<div class="sidebar-wrapper sidebar-theme">
  <div id="menuLateral">
    <nav id="sidebar">
      <div class="shadow-bottom"></div>
      <ul class="list-unstyled menu-categories ps" id="accordionExample">
        <li *ngFor="let item of menuItems; let i = index" class="menu">
          <a
            data-toggle="collapse"
            aria-expanded="false"
            href="#{{ 'menu-' + i }}"
            class="dropdown-toggle cursor-pointer"
          >
            <div class="">
              <svg>
                <use
                  class="iconeMenuLateral"
                  attr.xlink:href="{{ item.icone }}"
                ></use>
              </svg>
              <span>{{ item.nome }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>
          <ul
            class="submenu list-unstyled collapse"
            id="{{ 'menu-' + i }}"
            data-parent="#accordionExample"
          >
            <li *ngFor="let funcionalidade of item.item">
              <a
                class="cursor-pointer"
                (click)="setAxis()"
                [routerLink]="funcionalidade.rotaSistema"
                routerLinkActive="texto-active"
                >{{ funcionalidade.nome }}</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</div>
