import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

type DebitoService = {
  idDebito: number,
  idDebitoOrigem: number,
  descricao: string,
  dataLimite: string
}

type DebitoDataLimite = {
  debito: DebitoService,
  dataLimite: string,
}

type ObjSearch = {
  dataFim: Date,
  dataInicio: Date,
  descricaoServico?: string,
  idServicoOrigem: number
}

@Component({
  selector: 'app-limpeza-financeiro',
  templateUrl: './limpeza-financeiro.component.html',
  styleUrls: ['./limpeza-financeiro.component.scss']
})
export class LimpezaFinanceiroComponent implements OnInit {
  public debitosService: Array<DebitoService> = []
  public listDebitos: Array<any> = []
  public isShow: boolean = false
  public isShowEmLote: boolean = false
  private currentDebitoService: DebitoService = null
  private dataLimiteCancelamento: string = null

  private objSearch: ObjSearch = {
    dataFim: null,
    dataInicio: null,
    idServicoOrigem: null,
    descricaoServico: null
  }

  constructor() { }

  ngOnInit(): void {
    this.carregaTodosServicosDoDebitoOrigem()
  }

  clickModal() {
    this.isShow = !this.isShow
  }

  setDataLimite(event: any) {
    this.dataLimiteCancelamento = event.target.value
  }

  setCurrentDebito(d: DebitoService) {
    this.currentDebitoService = d
    this.isShow = true
  }

  handleDataLimite() {
    const obj: DebitoDataLimite = {
      debito: this.currentDebitoService,
      dataLimite: this.dataLimiteCancelamento
    }

    const url = `${environment.backofficeUrl}/registra-data-limite/${obj.debito.idDebito}`
    fetch(url, {
      method: "POST",
      body: JSON.stringify(obj)
    }).then(res => {

      if (res.ok) {
        this.isShow = false
        alert("Salvo com sucesso")
      } else {
        alert("Falhou ao tentar salvar no banco de dados")
      }
    })
  }

  mapSituacao() {
    return {
      1: "Pendente",
      2: "Aguardando simulação",
      3: 'Pago',
      4: 'Cancelado',
      5: 'Negado',
      6: "Recusado",
    }
  }

  pesquisarDebitos() {
    if (!this.objSearch.dataInicio || !this.objSearch.dataFim || !this.objSearch.idServicoOrigem) {
      throw alert("Preencha todos os campos para conseguir fazer a pesquisa")
    }

    const currentUrl: string = `${environment.backofficeUrl}/pega-todos-debitos-pendentes`

    fetch(currentUrl, {
      method: 'POST',
      body: JSON.stringify(this.objSearch)
    }).then(res => {

      res.json().then(list => {
        this.listDebitos = list.map(a => {
          a.dataLimite = a.dataLimite ? new Date(a.dataLimite).toLocaleDateString() : ""
          return a
        })
      })
    })
  }

  limparDataLimite(debitoId: number) {
    const url = `${environment.backofficeUrl}/limpar-data-limite/${debitoId}`

    fetch(url, {
      method: 'POST'
    }).then(res => {
      if (res.ok) {
        alert("Salvo com sucesso")
      } else {
        alert("Falhou ao tentar salvar no banco de dados")
      }
    })

  }

  definirDataLimiteEmLote() {
    const url = `${environment.backofficeUrl}/registrar-data-em-lote`

    fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        nextDate: this.dataLimiteCancelamento,
        data: this.listDebitos.map(({ idDebito }) => idDebito)
      })
    }).then(res => {
      if (res.ok) {
        alert("Salvo com sucesso")
      } else {
        alert("Falhou ao tentar salvar no banco de dados")
      }
    })
  }

  async carregaTodosServicosDoDebitoOrigem() {
    const currentUrl = `${environment.backofficeUrl}/pega-debitos-origem`
    const res = await fetch(currentUrl)
    const list = await res.json()
    this.debitosService = list
  }

  setDataInicio(event: any) {
    this.objSearch.dataInicio = event.target.value
  }

  setDataFim(event: any) {
    this.objSearch.dataFim = event.target.value
  }

  servicoSelecionado(event: any) {
    this.objSearch.idServicoOrigem = event.currentTarget.value
  }

  setDescricaoServico(event: any) {
    this.objSearch.descricaoServico = event.currentTarget.value
  }

}
