<div class="rodape">
    <div class="row no-gutters">
        <div class="col-md-9 col-sm-12 direitos">
            © {{dataAtual}} <a target="_blank"
               href="">Planejar</a>. Todos os direitos reservados.
        </div>

        <div class="versao col-md-3 col-sm-12">
            © {{versao}}
        </div>
    </div>
</div>