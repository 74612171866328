
<li class="nav-item dropdown planejar-profile-dropdown"
    [class.show]="showCompaniesDropdown"
    [class.user-atach]="true"
    >
  <a href="javascript:void(0);"
     class="dropdown-toggle"
     id="planejarProfileDropdown"
     data-toggle="dropdown"
     aria-haspopup="true"
     aria-expanded="true"
     title=""
     >
     <svg class="icone-header">
      <use
        class=""
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#planejar-logo"
      ></use>
    </svg>
  </a>
  <div class="dropdown-menu position-absolute"
       [class.show]="showCompaniesDropdown"
       aria-labelledby="planejarProfileDropdown"
       style="margin-right: 10px;">
    <p>Sistemas</p>
    <div class="logosPlanejar">
      <div>

        <a href="" target="_blank" title="Acto">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#logo"
            ></use>
          </svg>
        </a>
      </div>
      <div>
        <a href="" target="_blank" title="Siss">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#logo"
            ></use>
          </svg>
        </a>
      </div>
      <div>
        <a href="" target="_blank" title="Gde">
          <svg class="icone-solucoes">
            <use
              class=""
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#logo"
            ></use>
          </svg>
        </a>
      </div>
    </div>
  </div>
</li>

