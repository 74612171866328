import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknEstrutura'),
    });
  }

  obterVersao() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/dados-sistema/obter-versao`,
      {
        headers: this.httpHeaders,
      }
    );
  }

  validaToken(){
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token/validar-token`, '',
        {
          headers: new HttpHeaders().set('Authorization', localStorage.getItem('tknEstrutura'))
        }
      )
  }
}
