import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';
import { AuthenticationService } from "src/app/services/authentication/authentication.service";

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.scss']
})
export class AlterarSenhaComponent implements OnInit {
  private unsubscribe = new Subject<void>();
  frAlterarSenha: FormGroup;
  chaveValidacao = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
    this.frAlterarSenha = this.formBuilder.group(
      {
        novaSenha: ['', [Validators.required, Validators.minLength(6)]],
        confirmarNovaSenha: ['', [Validators.required]],
      },
      {
        validator: this.verificaSenhas('novaSenha', 'confirmarNovaSenha')
      }
    );
  }

  verificaSenhas(senha: string, senhaConfirmada: string) {
    return (group: FormGroup) => {
      let valorSenha = group.controls[senha],
        valorSenhaConfirmada = group.controls[senhaConfirmada];
      if (valorSenha.value !== valorSenhaConfirmada.value) {
        return valorSenhaConfirmada.setErrors({ senhasDivergentes: true })
      }
      if (valorSenhaConfirmada.value === '') {
        return valorSenhaConfirmada.setErrors({ obrigatoriedade: true })
      }
      else {
        return valorSenhaConfirmada.setErrors(null);
      }
    }
  }

  // Reconhece os campos
  get novaSenha() {
    return this.frAlterarSenha.get('novaSenha');
  }
  get confirmarNovaSenha() {
    return this.frAlterarSenha.get('confirmarNovaSenha');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.chaveValidacao = params['chaveValidacao'];
    });
  }

  submit(form) {
    if (this.frAlterarSenha.valid) {
      this.authenticationService
        .alterarSenha(
          {
            chaveValidacao: this.chaveValidacao,
            senha: this.frAlterarSenha.value.novaSenha,
            senhaConfirmacao: this.frAlterarSenha.value.confirmarNovaSenha
          })
        .subscribe(
          (res) => {
            Swal.fire(
              'Alterar Senha',
              `Senha alterada com sucesso!`,
              'success'
            ).then(() => {
              this.router.navigate(['/login']);
            });
          },
          (error) => {
            Swal.fire('Alterar Senha', error.error.excecao[0].mensagem, 'error');
          }
        );
    }
    else {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }

  }

}
