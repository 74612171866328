import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
// import { dataTabeOptions } from 'src/app/utils/datatable-options';
import * as $ from 'jquery';
import 'datatables.net';

import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/services/authentication/authentication.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  loginForm: FormGroup;
  esqueciSenhaForm: FormGroup;
  esqueciSenha: boolean;
  selecionarEstrutura: boolean;
  estruturas: any;
  public tabela: any;
  esqueciSenhaMsg = {
    type: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      senha: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.esqueciSenhaForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  // Reconhece os campos
  get login() {
    return this.loginForm.get('login');
  }
  get senha() {
    return this.loginForm.get('senha');
  }
  get email() {
    return this.esqueciSenhaForm.get('email');
  }

  ngOnInit(): void {
    this.initDatatable();
    if (this.router.url === '/selecionar-estrutura') {
      this.estruturas = JSON.parse(localStorage.getItem('estruturas'));
      this.selecionarEstrutura = true;
      this.reInitDatatable();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private initDatatable(): void {
    let tabela: any = $('#tabelaSelecaoEstrutura');
    this.tabela = tabela.DataTable({
      lengthMenu: false,
      scrollY: '200px',
      paging: false,
      bInfo: false,
      language: {
        sEmptyTable: 'Nenhum registro encontrado',
        sInfo: '',
        sInfoEmpty: '',
        sInfoFiltered: '',
        sInfoPostFix: '',
        sInfoThousands: '.',
        sLengthMenu: '',
        sLoadingRecords: 'Carregando...',
        sProcessing: 'Processando...',
        sZeroRecords: 'Nenhum registro encontrado',
        sSearch: '',
        searchPlaceholder: 'Pesquisar',
        oPaginate: {},
        oAria: {
          sSortAscending: ': Ordenar colunas de forma ascendente',
          sSortDescending: ': Ordenar colunas de forma descendente',
        }
      },
      aaSorting: [],
    });
  }

  private reInitDatatable(): void {
    if (this.tabela) {
      this.tabela.destroy();
      this.tabela = null;
    }
    setTimeout(() => this.initDatatable(), 0);
  }

  recuperarSenha(esqueciSenhaForm) {
    if (this.esqueciSenhaForm.valid) {
      this.authenticationService
        .recuperarSenha(this.esqueciSenhaForm.value.email)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (res) => {
            Swal.fire(
              'Recuperar Senha',
              `Verifique seu e-mail para dar continuidade ao processo de recuperação de senha!`,
              'success'
            ).then(() => {
              this.esqueciSenha = false;
              this.limpaEsqueciSenha();
            });
          },
          (err) => {
            Swal.fire('Recuperar Senha', err.error.excecao[0].mensagem, 'error');
          }
        );
    } else {
      let form = esqueciSenhaForm.get('email');
      form.markAsTouched({ onlySelf: true });
    }
  }

  submit(form) {
    if (this.loginForm.valid) {
      this.authenticationService
        .login(
          this.loginForm.value.login,
          this.loginForm.value.senha.replace(/(\.|\/|\-)/g, '')
        )
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (user: any) => {
            this.estruturas = JSON.parse(user.estruturas);

            if (this.estruturas.length === 1) {
              this.logarEstrutura(
                this.estruturas[0].descricao,
                this.estruturas[0].idEstrutura
              );
            } else {
              this.selecionarEstrutura = true;
              this.reInitDatatable();
            }
          },
          (err) => {
            Swal.fire('', 'Usuário e/ou senha inválidos.', 'error');
          }
        );
    } else {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  logarEstrutura(name: string, id: string) {
    this.authenticationService
      .logarEstrutura(name, id)
      .subscribe(function (res) {
        this.estrutura = name;
      });
  }

  limpaEsqueciSenha() {
    this.esqueciSenhaMsg = {
      type: '',
    };
    this.email.reset();
  }
}
