import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CoreComponent } from './components/core/core.component';
import { LoginComponent } from './pages/outros/autenticacao/login/login.component';
import { AuthGuard } from './services/authentication/auth.guard';
import { NaoEncontradoComponent } from './pages/outros/erros/nao-encontrado/nao-encontrado.component';
import { AlterarSenhaComponent } from "./pages/outros/autenticacao/alterar-senha/alterar-senha.component";

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'alterar-senha',
    component: AlterarSenhaComponent,
  },
  {
    path: 'selecionar-estrutura',
    component: LoginComponent,
  },
  {
    path: '',
    redirectTo: 'configuracoes',
    pathMatch: 'full',
  },
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'configuracoes',
        data: {
          breadcrumb: 'Inicio',
        },
        loadChildren: () =>
          import('./pages/configuracoes/configuracoes.module').then(
            (m) => m.ConfiguracoesModule
          ),
      },
      {
        path: 'operacional',
        data: {
          breadcrumb: 'Inicio',
        },
        loadChildren: () =>
          import('./pages/operacional/operacional.module').then(
            (m) => m.OperacionalModule
          ),
      }
    ],
  },
  {
    path: '**',
    component: NaoEncontradoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
