import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-notificacoes",
  templateUrl: "./notificacoes.component.html",
  styleUrls: ["./notificacoes.component.scss"],
})
export class NotificacoesComponent {
  @Input() showNotificationDropdown: boolean;
  @Output() clickNotificationDropDown = new EventEmitter();

  constructor() {}
}
