<div class="container-principal">
  <div class="row mb-3">
    <h5>Limpeza da base de dados do Financeiro.</h5>
    <div class="col-md-12">


      <div class="row">

        <div class="col-2">
          <label for="">Data inicio</label>
          <input type="date" (change)="setDataInicio($event)" class="form-control">
        </div>

        <div class="col-2">
          <label for="">Data fim</label>
          <input type="date" (change)="setDataFim($event)" class="form-control">
        </div>

        <div class="col-2">
          <label for="">Descrição Serviço</label>
          <input type="text" (change)="setDescricaoServico($event)" class="form-control">
        </div>


        <div class="col-3">
          <label for="">Serviços</label>
          <select (change)="servicoSelecionado($event)" class="form-control">
            <option>Selecione...</option>
            <option *ngFor="let debitoService of debitosService"
              value="{{debitoService.idDebitoOrigem}}">
              {{debitoService.descricao}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-2">
          <br />
          <button (click)="pesquisarDebitos()" class="btn btn-primary">Pesquisar</button>
        </div>

        <div class="col-3">
          <br />
          <button (click)="isShowEmLote = true" class="btn btn-danger">Definir data Limite em Lote</button>
        </div>

      </div>


      <div class="table-wrapper table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Nome</th>
              <th>Situação</th>
              <th>Valor</th>
              <th>Valor Pago</th>
              <th>Cpf</th>
              <th>Situação</th>
              <th>Data Limite</th>
              <th>Descricao Servico</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let debito of listDebitos">
              <td>{{debito.idDebito}}</td>
              <td>{{debito.nomeRazaoSocial == null || debito.nomeRazaoSocial == "" ? debito.nome : debito.nomeRazaoSocial}}</td>
              <td>{{debito.situacao}}</td>
              <td>{{debito.valorCobranca}}</td>
              <td>{{debito.valorPago}}</td>
              <td>{{debito.cpfCnpj == null || debito.cpfCnpj == "" ? debito.cpf : debito.cpfCnpj}}</td>
              <td>{{debito.idDebitoSituacao}}</td>
              <td>{{debito.dataLimite}}</td>
              <td>{{debito.descricaoServico}}</td>
              <td><button (click)="setCurrentDebito(debito)">Programar</button></td>
              <td><button (click)="limparDataLimite(debito.idDebito)">Cancelar</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal-here" *ngIf="isShowEmLote">
  <p>Definir data para cancelar em lote</p>
  <input class="form-control" type="date" (change)="setDataLimite($event)" />
  <br/>
  <button class="btn btn-sm btn-primary" (click)="definirDataLimiteEmLote()">Salvar</button>
  <button class="btn btn-sm btn-danger float-right" (click)="isShowEmLote = false">Close</button>
</div>

<div class="modal-here" *ngIf="isShow">
  <p>Definir data limite para o cancelamento</p>
  <input class="form-control" type="date" (change)="setDataLimite($event)" />
  <br/>
  <button class="btn btn-sm btn-primary" (click)="handleDataLimite()">Salvar</button>
  <button class="btn btn-sm btn-danger float-right" (click)="clickModal()">Close</button>
</div>
