import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { FooterService } from '../app/services/footer/footer.service';
import { AuthenticationService } from '../app/services/authentication/authentication.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "datta-able";

  constructor(
    private router: Router,
    private footerService: FooterService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.footerService
    .validaToken()
    .subscribe( res=> {
      if(res.identificador === 'False'){
        this.authenticationService
        .logout()
      }
    }, erro => {
      console.log(erro.error);
    })

  }
}
