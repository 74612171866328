import { Component, EventEmitter, Output } from "@angular/core";
import { DattaConfig } from "../../../app-config";

import { AuthenticationService } from "../../../services/authentication/authentication.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  public showUserDropdown = false;
  public showEixosDropdown = false;
  public showHelpDropDown = false;
  public showNotificationDropdown = false;
  public showCompaniesDropdown = false;
  public nomeCliente: string;
  public organizacaoCliente: string;
  public perfilCliente: string;

  logo = null;
  
  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.nomeCliente = this.authenticationService.currentUserValue.nomeFantasia;
    this.organizacaoCliente = this.authenticationService.currentUserValue.empresa;
    this.perfilCliente = this.authenticationService.currentUserValue.infos.perfil[0].descricao;
  }
}
