import { Component, OnInit } from '@angular/core';
import { FooterService } from '../../../services/footer/footer.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
versao = '';
  constructor(private footerService: FooterService) { }

  dataAtual = new Date().getFullYear();

  ngOnInit(): void {
    this.versaoProduto();
  }

  versaoProduto(){
    this.footerService.obterVersao().subscribe(
      (res) => {
        this.versao = res.resultado;
      }
    );
  }
}
