<li
  class="nav-item dropdown notification-dropdown"
  [class.show]="showNotificationDropdown"
  [class.user-not]="true"
>
  <a
    href="javascript:void(0);"
    class="nav-link dropdown-toggle"
    id="notificationDropdown"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    title="Alertas"
  >
    <svg class="icone-header">
      <use
        class=""
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xlink:href="#sino_alerta_Azul"
      ></use>
    </svg><span class="badge badge-success"></span>
  </a>
  
  <div
    class="dropdown-menu position-absolute"
    [class.show]="showNotificationDropdown"
    aria-labelledby="notificationDropdown"
  >
    <div class="notification-scroll" *ngFor="let number of [1,2,3]; let i = index">
      <div class="dropdown-item cursor-pointer">
        <div class="media">
          <svg class="logo">
            <use
              class="color-envelope"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              xlink:href="#mail_envelope"
            ></use>
          </svg>
          <div class="media-body">
            <div class="notification-para">
               Nova mensagem de teste {{i + 1}}.
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</li>
