import { Component, EventEmitter, Output } from '@angular/core';
import { DattaConfig } from '../../../app-config';

import { AuthenticationService } from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent {
  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavCollapsedMob = new EventEmitter();
  public dattaConfig: any;
  public navCollapsedMob;
  public currentAxis: string;

  constructor(private authenticationService: AuthenticationService) {
    this.dattaConfig = DattaConfig.config;
    this.navCollapsedMob = false;
    this.authenticationService.currentAxis.subscribe((axis) => {
      this.currentAxis = axis.descricao;
    });
  }
}
