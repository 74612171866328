import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

// import { Users } from 'src/models/users';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private currentAxisSubject: BehaviorSubject<any>;
  public currentAxis: Observable<any>;
  public estrutura: string;
  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    );
    this.currentAxisSubject = new BehaviorSubject<any>(
      localStorage.getItem('eixos')
        ? localStorage.getItem('currentAxis')
          ? JSON.parse(localStorage.getItem('eixos'))[
          localStorage.getItem('currentAxis')
          ]
          : JSON.parse(localStorage.getItem('eixos'))[0]
        : null
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentAxis = this.currentAxisSubject.asObservable();
    this.httpHeaders = new HttpHeaders({
      Authorization: localStorage.getItem('tknEstrutura')
    });
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get currentAxisValue(): any {
    return this.currentAxisSubject.value;
  }

  public setCurrentAxis(index) {
    localStorage.setItem('currentAxis', index);
    this.currentAxisSubject.next(
      localStorage.getItem('eixos')
        ? JSON.parse(localStorage.getItem('eixos'))[index]
        : null
    );
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token`,
        {
          username,
          password,
          grant_type: 'password',
          tipoLogin: 0,
          sessaoCore: false,
          IdEstrutura: 1
        },
        {
          headers: new HttpHeaders({
            PARAM_USER: 'ORG',
            'Content-Type': 'application/json',
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((user) => {
          localStorage.setItem('estruturas', user.estruturas);
          localStorage.setItem('userId', user.codigo_usuario);
          localStorage.setItem('tkn', 'Bearer ' + user.access_token);
          this.currentUserSubject.next(user);
          this.setCurrentAxis(0);
          return user;
        })
      );
  }

  logarEstrutura(name: string, id: any) {
    this.estrutura = name;
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token`,
        {
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem('tkn').replace('Bearer ', ''),
          IdEstrutura: 1
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            CODIGO_USUARIO: localStorage.getItem('userId'),
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((estrutura) => {
          localStorage.setItem('eixos', estrutura.menus);
          localStorage.setItem('infos', estrutura.informacoes);
          localStorage.setItem(
            'tknEstrutura',
            'Bearer ' + estrutura.access_token
          );
          this.httpHeaders = new HttpHeaders({
            Authorization: 'Bearer ' + estrutura.access_token
          });
          this.retornoLogin(estrutura);
          return estrutura;
        })
      );
  }

  retornoLogin(estrutura: any) {
    this.obterFoto().subscribe((resFoto) => {
      estrutura.foto = resFoto.resultado.foto;
      estrutura.empresa = this.estrutura;
      estrutura.empresas = localStorage.getItem('estruturas');

      estrutura.entidade = {};
      estrutura.entidade.cidade = 'São Paulo';
      estrutura.logo = estrutura.foto;
      estrutura.razaoSocial = JSON.parse(estrutura.informacoes).nome;
      estrutura.nomeFantasia = JSON.parse(estrutura.informacoes).nome;

      estrutura.infos = JSON.parse(estrutura.informacoes);

      JSON.parse(estrutura.menus).map((eixo) => {
        eixo.name = eixo.rota ? eixo.rota.split('.')[1] : '';
        eixo.item.map((menu) => {
          menu.name = menu.rota ? menu.rota.split('.')[2] : '';
          menu.item.map((funcionalidade) => {
            funcionalidade.name = funcionalidade.rota
              ? funcionalidade.rota.split('.')[3]
              : '';
          });
        });
      });

      localStorage.setItem('user', JSON.stringify(estrutura));
      this.setCurrentAxis(0);
      this.currentUserSubject.next(estrutura);
      this.router.navigate([JSON.parse(estrutura.menus)[0].rota.split('.')[1]]);
    });
  }

  obterFoto() {
    return this.http.get<any>(
      `${environment.backofficeUrl}/token/obter-dados-usuario`,
      {
        headers: new HttpHeaders({
          Authorization: localStorage.getItem('tknEstrutura'),
          'Content-Type': 'application/x-www-form-urlencoded',
          PARAM_LOG: '',
        }),
      }
    );
  }

  recuperarSenha(email: string) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token/solicitar-renovacao-senha?email=${email}`,
        {
          headers: new HttpHeaders({
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }

  alterarSenha(novaSenha: any) {
    return this.http
      .post<any>(
        `${environment.backofficeUrl}/token/criar-senha-token`, novaSenha,
        {
          headers: new HttpHeaders({
          }),
        }
      )
      .pipe(
        shareReplay(),
        map((res) => {
          return res;
        })
      );
  }


  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('tkn');
    localStorage.removeItem('tknEstrutura');
    localStorage.removeItem('estruturas');
    localStorage.removeItem('informacoes');
    localStorage.removeItem('eixos');
    localStorage.removeItem('consultaTrilha');
    document.location.reload(true);
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
}
