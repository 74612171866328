import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";

import { LoaderService } from "src/app/services/loader/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  isLoading: boolean;

  constructor(private loaderService: LoaderService) {}
  ngOnInit() {
    this.loaderService.isLoading.subscribe((event) => {
      this.isLoading = event;
    });
  }
}
