<div class="containerLogin">
  <form class="containerLogin_formulario row no-gutters justify-content-center align-items-center"
        name="frAlterarSenha"
        autocomplete="off"
        [formGroup]="frAlterarSenha"
        (ngSubmit)="submit(frAlterarSenha)">
    <div class="col-md-4 col-sm-6">
      <div class="card m-3 px-3">
        <div class="card-body">
          <div class="form-row">
            <div class="col-12 d-flex justify-content-center mt-3">
              <img class="logo"
                   src="../../../../../assets/img/logos/logo-planejar.svg" />
            </div>
            <div class="col-12 d-flex justify-content-center mt-4">
              <h1 class="containerLogin_formulario_titulo">Alterar Senha</h1>
            </div>
            <div class="form-group col-12 mt-4">
              <label class="texto--sm"
                     for="novaSenha">Nova Senha</label>
              <input class="form-control form-control-sm"
                     [class.is-invalid]="novaSenha.invalid && (novaSenha.touched)"
                     formControlName="novaSenha"
                     id="novaSenha"
                     name="novaSenha"
                     placeholder="Nova Senha"
                     type="password" />
              <div *ngIf="novaSenha.invalid && novaSenha.touched">
                <span class="erro"
                      *ngIf="novaSenha.errors.required">Campo obrigatório</span>
                <span class="erro"
                      *ngIf="novaSenha.errors.minlength">A senha deve conter ao menos 6 caracteres.</span>
              </div>
            </div>
            <div class="form-group col-12 mt-4">
              <label class="texto--sm"
                     for="confirmarNovaSenha">Confirmar Nova Senha</label>
              <input class="form-control form-control-sm"
                     [class.is-invalid]="confirmarNovaSenha.invalid && (confirmarNovaSenha.touched)"
                     formControlName="confirmarNovaSenha"
                     id="confirmarNovaSenha"
                     name="confirmarNovaSenha"
                     placeholder="Confirmar Nova Senha"
                     type="password" />
              <div *ngIf="confirmarNovaSenha.invalid && confirmarNovaSenha.touched">
                <span class="erro"
                      *ngIf="confirmarNovaSenha.errors.obrigatoriedade">Campo obrigatório</span>
                <span class="erro"
                      *ngIf="confirmarNovaSenha.errors.senhasDivergentes">As senhas digitadas não são iguais, favor
                  verificar</span>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group col-12">
              <button class="btn btn-block btn-primary"
                      title="Acessar"
                      type="submit"
                      id="btnAcessar">
                Concluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="containerLogin_rodape d-flex justify-content-center align-items-center">
    <span>&copy; 2020 | Desenvolvido por Planejar</span>
  </div>
</div>