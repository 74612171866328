<li class="nav-item dropdown user-profile-dropdown"
    [class.show]="showEixosDropdown">
  <a href="javascript:void(0);"
     class="nav-link dropdown-toggle user"
     id="userProfileDropdown"
     data-toggle="dropdown"
     aria-haspopup="true"
     aria-expanded="true"
     title="Eixos">
    <div class="icone-header">
      <svg class="icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink"
             xlink:href="#squares2_azul"></use>
      </svg>
    </div>
  </a>

  <div class="dropdown-menu position-absolute"
       [class.show]="showEixosDropdown"
       aria-labelledby="userProfileDropdown"
       style="margin-left: -3rem">
    <div class="">
      <div *ngFor="let eixo of eixos; let i = index"
           class="dropdown-item">
        <a class="cursor-pointer"
           (click)="setAxis(i)"
           [routerLink]="['/' + eixo.name]">
          <svg>
            <use class="iconeMenu"
                 attr.xlink:href="assets/sprite.svg{{ eixo.icone }}"></use>
          </svg>
          {{ eixo.descricao }}</a>
      </div>
    </div>
  </div>
</li>