import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Ng2CompleterModule } from "ng2-completer";
import { DatePipe } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

registerLocaleData(ptBr);

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NaoEncontradoComponent } from "./pages/outros/erros/nao-encontrado/nao-encontrado.component";
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { InlineSVGModule } from "ng-inline-svg";
import { HomeComponent } from "./pages/home/home.component";
import { CoreComponent } from "./components/core/core.component";
import { ComponentsModule } from "./components/components.module";
import { LoginComponent } from "./pages/outros/autenticacao/login/login.component";
import { AlterarSenhaComponent } from "./pages/outros/autenticacao/alterar-senha/alterar-senha.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { NgxSummernoteModule } from 'ngx-summernote';

import { LoaderService } from "./services/loader/loader.service";
import { LoaderInterceptor } from "./services/loader/loader.interceptor";
import { LoaderComponent } from "./components/loader/loader.component";
import { LimpezaFinanceiroComponent } from './pages/configuracoes/limpeza-financeiro/limpeza-financeiro.component';
import { RelatorioComprovantePagamentoComponent } from './pages/operacional/relatorio-comprovante-pagamento/relatorio-comprovante-pagamento.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AlterarSenhaComponent,
    CoreComponent,
    NaoEncontradoComponent,
    LimpezaFinanceiroComponent,
    RelatorioComprovantePagamentoComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    ComponentsModule,
    Ng2CompleterModule,
    // NgxSummernoteModule
  ],
  providers: [
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
