import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-relatorio-comprovante-pagamento',
  templateUrl: './relatorio-comprovante-pagamento.component.html',
  styleUrls: ['./relatorio-comprovante-pagamento.component.scss']
})
export class RelatorioComprovantePagamentoComponent implements OnInit {
  public showAguarde = false
  ngOnInit(): void {
  }

  uploadFile() {
    const form = new FormData()

    const file: any = document.querySelector('#uploadFile')
    const f = file.files[0]

    form.append('file', f, f.name)

    this.showAguarde = true
    fetch(`${environment.backofficeUrl}/upload_comprovantes_debitos_csv`, {
      method: 'POST',
      body: form
    }).then(res => {

      if(!res.ok) {

        alert("Não foi possível fazer o envio do arquivo")
      }

      this.showAguarde = false
    })
  }
}
