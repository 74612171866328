<div class="container-principal">

  <div class="form-group col-md-4 col-sm-4">
    <strong>Atualizar todas as TID do relatório</strong>
    <br>
    <br>
    <label class="texto--sm" for="tipo">Upload Arquivo CSV</label>
    <br>
    <small>Somente relatório XLSX</small>
    <br>
    <input id="uploadFile" class="form-control" type="file" />

  </div>

  <div class="col-4">
    <button class="form-control" (click)="uploadFile()" type="submit">Enviar</button>
    <label *ngIf="showAguarde" style="color: red;">Enviando, aguarde...</label>
  </div>
</div>
